<template>
  <v-container fluid-xl class="py-12 px-xl-12" id="our-services">
    <v-row class="py-12">
      <v-col cols="12">
        <h1 class="secondary--text text-center">
          Nuestros productos para gobierno
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="6"
        lg="4"
        v-for="(item, index) in products"
        :key="index"
      >
        <v-card outlined height="100%" class="rounded-lg">
          <v-lazy>
            <v-img
              gradient="to top right, rgba(0,0,0,0.3), rgba(0,0,0,0.3)"
              :src="item.image"
            ></v-img>
          </v-lazy>
          <v-card-text class="text-center">
            <h3 class="secondary--text font-weight-bold mb-3">
              <small>{{ item.product }}</small>
            </h3>
            <v-btn
              color="secondary"
              outlined
              depressed
              rounded
              small
              :href="item.url"
            >
              Ver detalles
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.image-card {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.v-card {
  overflow: hidden;
}
* {
  word-break: break-word !important;
}
</style>
<script>
export default {
  name: "HelloWorld",
  props: ["imgSrc"],
  data: () => ({
    //
    products: [
      {
        product: "Software de recaudación segmentado por dirección",
        url: "https://division-gobierno.herokuapp.com/recaudacion",
        image: "/images/gobierno/recaudacion.jpg",
      },
      {
        product: "App para tesorería y altos mandos",
        url: "https://division-gobierno.herokuapp.com/app-tesoreria",
        image: "/images/gobierno/auditoria.jpg",
      },
      {
        product:
          "App reloj checador para controlar al personal del ayuntamiento",
        url: "https://division-gobierno.herokuapp.com/app-checador",
        image: "/images/gobierno/checador.jpg",
      },
      {
        product:
          "Software de seguimiento de trámites en línea sin acudir de manera presencial",
        url: "https://division-gobierno.herokuapp.com/tramites",
        image: "/images/gobierno/queue.jpg",
      },
      {
        product: "Software para administrar los trámites de cada dependencia",
        url: "https://division-gobierno.herokuapp.com/administrador-de-tramites",
        image: "/images/gobierno/administrator.jpg",
      },
      {
        product: "Página web para pago de SIAPA e impuesto predial",
        url: "https://division-gobierno.herokuapp.com/siapa",
        image: "/images/gobierno/online-payment.jpg",
      },
    ],
  }),
};
</script>
